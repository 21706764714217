
;(function ($, window, document, undefined) {
    var script = {
        rem: function rem() {
            var html = document.getElementsByTagName('html')[0],
                setSize = function setSize() {
                var _w = document.documentElement.clientWidth;
                if (_w <= 750) {
                    html.style.fontSize = '50px';
                } else {
                    html.style.fontSize = '100px';
                }
            };
            setSize();
            window.onresize = setSize;
            return this;
        },
        nav: function nav() {
            var nav = $('#wap_nav');
            $('#wap_nav_btn').on('click', function (event) {
                event.stopPropagation();
                nav.addClass('in');
                $('#wapnav_mask').fadeIn();
            });
            $('#wapnav_mask').on('click', function () {
                nav.removeClass('in');
                $(this).fadeOut();
            });
            nav.on('click', function (event) {
                event.stopPropagation();
            }).find('a.close').on('click', function () {
                nav.removeClass('in');
            });
            nav.find('li').each(function () {
                var $this = $(this);
                var child = $this.children('a').next('ul').children('li');
                if (child.length) {
                    $this.addClass('mult')
                    .children('a').attr('href','javascript:;')
                    .click(function (event) {
                        var t = $(this);
                        if ($this.hasClass('active')) {
                            $this.removeClass('active').children('ul').slideUp();
                        } else {
                            event.preventDefault();
                            t.attr('href', t.attr('data-href'));
                            $this.addClass('active').children('ul').slideDown();
                        }
                    });
                }
            });
            // nav.find('>ul>li').each(function () {
            //     var $this = $(this);
            //     if ($this.find('li').length) {
            //         $this.addClass('mult');
            //         $this.find('>a').attr('href','javascript:;')
            //         $this.find('>a').click(function () {
            //             if ($this.hasClass('active')) {
            //                 $this.removeClass('active').find('ul').slideUp();
            //             } else {
            //                 $this.addClass('active').find('ul').slideDown();
            //             }
            //         });
            //     }
            // });
            return this;
        }
    };

    $(function () {
        script.rem().nav();
    });

    window.script = script;
})(jQuery, window, document);

;(($, window, document, undefined) => {
    window.yunu = {
        loadMore(config={}){
            config = $.extend(true, {
                key: true, // load开关
                url: '', // api url
                type: 'POST',
                btn: $('#load_more_btn'), // 加载更多按钮
                box: $('#list>ul'), // 加载内容的容器
                data: { // api接口配置参数
                    pages: 1,
                    limit: 5
                },
                beforeSend: ()=>{}, // 发送请求前
                done: ()=>{}, // 请求成功
                fail: ()=>{}, // 请求失败
                always: ()=>{}, // 请求完成
                load_icon: true, // 是否开启请求等待图标样式
                load_icon_class: '', // 图标尺寸样式 可选: la-sm / la-2x / la-3x
                loading: $('<div class="la-ball-fall"><div></div><div></div><div></div><div></div></div>'), // 按钮load icon元素
                load_more_none: $('<p class="load_more_none tac c_red mt20">没有了</p>') // 没有内容时显示的元素
            }, config);
            var load = function(){
                $.ajax({
                    url: config.url,
                    type: config.type,
                    data: config.data,
                    dataType: "jsonp",
                    beforeSend: function(XMLHttpRequest) {
                        config.beforeSend(XMLHttpRequest);
                        config.btn.attr('disabled',true);
                    }
                })
                .done(function(res, textStatus, XMLHttpRequest) {
                    config.done(res, textStatus, XMLHttpRequest);
                    if (!res.data.length || !config.data.limit || res.data.length < config.data.limit) {
                        config.btn.replaceWith(config.load_more_none);
                    }
                })
                .fail(function(XMLHttpRequest, textStatus, errorThrown) {
                    config.fail(XMLHttpRequest, textStatus, errorThrown);
                })
                .always(function(XMLHttpRequest, textStatus, errorThrown) {
                    config.always(XMLHttpRequest, textStatus, errorThrown);
                    config.key = true;
                    config.btn.attr('disabled',false);
                });
            }
            if (config.load_icon) {
                $(config.loading).addClass(config.load_icon_class); // 设置图标大小
                config.btn.after(config.loading); // 装载图标动画
            }
            config.btn.click(function(){
                if (!config.key) return;
                config.key = false;
                config.data.pages++;
                load();
            });
        }
    };

  
})(jQuery, window, document);
        